.catalogPageContainer {
  font-size: 24px;
  .search-bar {
    font-size: 0.8em;
    .logoDiv {
      display: flex;
      flex-direction: row-reverse;
      img {
        width: 19%;
      }
    }
    .searchDiv {
      width: 100%;
      font-size: 0.8em;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 4%;
      .input-container {
        height: 100%;
        width: 30%;
        position: relative;
        display: inline-block;
        input[type="text"] {
          padding: 3%;
          padding-left: 12%;
          padding-right: 10%;
          border: 0;
          outline: 0;
        }

        .searchIcon_en {
          position: absolute;
          left: 5%;
          top: 50%;
          transform: translateY(-50%);
          color: #ef1d1d;
          cursor: pointer;
        }
        .searchIcon_ar {
          position: absolute;
          right: 5%;
          top: 50%;
          transform: translateY(-50%);
          color: #ef1d1d;
        }

        input {
          padding: 0px 20px;
          height: auto;
          max-height: 45px;
          min-height: 35px;
          width: 100%;
          border-radius: 20px;
          border: 1px solid #ccc;
        }
      }
      p {
        font-size: 1.4em;
        font-weight: 900;
      }
    }
    text-align: center;
    padding: 20px;
    background-color: #e0f7fa;
    border-radius: 20px;
    padding: 5%;
  }

  .category-selection {
    justify-content: center;

    .catalogs {
      margin-top: 20px;
      display: flex;
      width: 100%;
      margin-right: 10px;
      gap: 2%;
      flex-wrap: wrap;
      .catalogDiv {
        width: 14%;
        height: 230px;
        background-color: #fafafa;
        padding: 1%;
        display: flex;
        margin-bottom: 3%;
        justify-content: center;
        border-radius: 20px;
        .category-link {
          margin: 0%;
          padding: 0%;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          justify-content: center;
          .catelogIcon {
            width: 40px;
            max-height: 40px;
            margin: 0 auto;
          }
          i {
            text-align: center;
            font-size: 0.8em;
            font-weight: 900;
            font-style: normal;
            margin-top: 10px;
          }
          .globe-services-icon {
            color: #05c3de;
          }
        }
      }
      .catalogDiv:hover {
        transform: scale(1.1);
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 22px;
      }
      .selected {
        transform: scale(1.1);
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 22px;
      }
      .selected i {
        color: #05c3de !important;
      }
    }
  }
  .popular-services {
    margin-top: 40px;
    .service-list {
      margin-top: 20px auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      gap: 2%;

      .service-item {
        height: 400px;
        border-radius: 25px;
        background-color: #fff;
        margin-bottom: 3%;
        overflow: hidden;
        max-width: 280px;
        width: 100%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        position: relative;

        .offer-tag {
          position: absolute;
          top: 10px;
        }
        .offer-tag span {
          background-color: #05c3de;
          color: #fff;
          font-size: 0.9rem;
          padding: 7px 8px 7px 7px;
          font-weight: 600;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          text-transform: uppercase;
        }

        .service-info {
          padding: 0%;
          width: 100%;
          margin: 0%;
          font-size: 1em;
          position: absolute;
          bottom: 0;

          p {
            padding: 15px;
            text-align: center;
            color: #05c3de;
            font-size: 0.8em;
            font-weight: 600;
            background-color: #fafafa;
            margin: 0;
          }
          .addServiceLink {
            width: 100%;
            background-color: #ffb74d;
            border: none;
            margin: 0px;
            padding: 10px;
            cursor: pointer;
            font-size: 0.9rem;
            font-weight: 700;
            text-align: center;

            a {
              color: #fff;
            }
          }
        }
      }
    }
    .category-name {
      width: 100%;
      margin: 10px 0 30px 0;
    }
    .category-name h6 {
      font-weight: 800;
      font-size: 1rem;
      border-bottom: 4px solid #05c3de;
      width: fit-content;
      float: right;
    }
  }
  .related-services-heading {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333f48;
    font-weight: 800;
  }
  .catalog-detail-price .price-text {
    font-weight: 600;
    font-size: 1.2rem;
    color: #05c3de;
  }
  .catalog-detail-price .price {
    font-size: 1.2rem;
    margin-left: 10px;
    margin-right: 3px;
    font-weight: 400;
  }
}
.selectDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0%;
  padding-bottom: 0%;
  font-size: 0.9em;
  font-weight: 800;
  span {
    margin-bottom: 20px;
  }
}
.no-record-text {
  margin: 50px;
  text-align: center;
  font-size: 1rem;
  color: #ccc;
}
.rtl .catalogPageContainer .popular-services .category-name h6 {
  float: left;
}
.rtl .catalogPageContainer .popular-services .service-list .offer-tag span {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 2px 5px 2px 10px;
}

@media (max-width: 1024px) {
  .catalogPageContainer {
    font-size: 20px !important;
    .searchDiv {
      .input-container {
        width: 80% !important;
      }
    }
    .category-selection {
      .catalogs {
        .catalogDiv {
          width: 18%;
          height: 180px;
        }
      }
    }
    .popular-services {
      .service-list {
        gap: 5%;
        .service-item {
          width: 25%;
          margin-bottom: 5%;
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .catalogPageContainer {
    font-size: 12px !important;
    .category-selection {
      .catalogs {
        .catalogDiv {
          width: 30%;
          height: 110px;
        }
      }
    }
    .popular-services {
      .service-list {
        .service-item {
          width: 45%;
        }
      }
    }
  }
}
