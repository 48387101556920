.wrapper {
  margin: 50px auto !important;
  padding: 40px;
  width: 90%;
  text-align: left;
  background-color: #f3f4f5;
  .imageContainer {
    width: 100%; /* Or a percentage */
    max-width: 500px;
    margin-bottom: 50px;
    img {
      width: 100%;
      height: auto; /* Maintains aspect ratio */
      max-width: 100%;
      display: block;
    }
    .img_ar {
      text-align: right;
    }
  }
  .englishPara p,
  .arabicPara p {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    font-family: "MarkPro" !important;

    .aljLink {
      color: #05c3de;
      cursor: pointer;
      text-decoration: underline;
      outline: none;
      border: none;
    }
  }
  .arabicPara p {
    text-align: right;
    font-size: 20px !important;
  }
}
