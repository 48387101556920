@media (max-width: 1367px) {
  #header .logo img {
    transform: scale(1.1);
  }

  .about_img {
    width: 100%;
    height: 100%;
  }

  .first {
    text-align: left;
    line-height: 35px;
  }

  .first h2 {
    margin-top: 7%;

    font-weight: 900 !important;
  }

  .first h2 span {
    display: block;

    font-weight: 900 !important;
  }

  .first p {
    font-size: 20px;
  }

  .mid {
    text-align: left;
    line-height: 35px;
  }

  .mid h2 {
    margin-top: 7%;

    font-weight: 900 !important;
  }

  .mid h2 span {
    display: block;
    line-height: 30px;
  }

  .mid p {
    font-size: 20px;
  }

  .gap {
    margin-bottom: 100px;
  }

  .second {
    font-size: 89px;
  }

  .second h2 span {
    display: block;
    font-weight: 900 !important;
    font-size: 89px;
  }

  .second h2 {
    font-weight: 900 !important;
    font-size: 89px;
  }

  .second p {
    font-size: 20px;
    margin-right: 27%;
  }

  .ring img {
    float: right;
  }

  .bring-it .upper {
    margin-top: 120px !important;
  }

  .recent_para {
    font-size: 20px !important;
    font-weight: 900;
  }

  .recent_maincontent {
    margin-top: 170px;
    justify-content: center;
    width: 50%;
    text-align: center;
    position: relative;
  }

  .recent_maincontenttwo {
    margin: 20px auto 8px auto;
    justify-content: center;
    width: 30%;
    text-align: center;
    position: relative;
    font-size: 16px;
  }

  .appointments_content {
    width: 100%;
    padding: 25px !important;
  }

  .offer-meta .card-navs button {
    margin-left: 0 !important;
  }

  body.rtl .offer-meta .card-navs button {
    margin-left: 15px !important;
  }

  .appointments_content h2 {
    font-size: 25px;
    font-weight: 800;
  }

  .our_partner {
    margin-bottom: 50px;
  }

  .premium img {
    width: 100%;
    margin: 50px 0 50px 0;
  }

  .partners {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .gird {
    margin: 50px auto 75px 30 px;
    width: 100%;
  }

  .bottom {
    margin-bottom: 125px;
  }

  .row.check {
    width: 100% !important;
  }

  td.rd-day-body {
    height: 87px !important;
  }

  .appointments_head h2 {
    font-size: 50px;
  }

  .re-appointment-btn button {
    margin: 5px 15px 5px 0 !important;
  }

  body.rtl .re-appointment-btn button {
    margin: 5px 0 5px 15px !important;
  }

  .personal-details .divider > div:not(:first-child) {
    width: 72% !important;
  }
}

@media (max-width: 1280px) {
  body {
    zoom: 0.8;
  }

  #hero,
  .captcha-block {
    zoom: 1.25;
  }

  .bring-it-appointments > div:not(.titles) {
    zoom: 0.9;
  }

  .recent_maincontent {
    width: 100%;
  }

  .recent_maincontenttwo {
    width: 100%;
  }

  .partner-img-col img {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  #header {
    zoom: 0.8;
    top: 50px;
  }

  .navbar:not(.navbar-mobile) ul {
    padding: 0 20px;
  }

  body,
  .captcha-block,
  #hero {
    zoom: 1;
  }

  #hero .slider-title {
    font-size: 30px;
    line-height: 34px;
  }

  #header .logo,
  .rtl #header .logo {
    transform: translateY(-5px);
  }

  #header .logo img {
    transform: scale(1);
  }

  .bottom-bar .bottom-bar-left,
  body.rtl .bottom-bar .bottom-bar-left {
    padding-right: 0;
    padding-left: 0;
  }

  #hero,
  #hero .carousel-item {
    height: 550px;
  }

  .recent-details-img img {
    width: 100%;
    border-width: 5px;
  }

  .button-and-expiry-wrap {
    width: 100%;
  }

  .offer-details-sec {
    width: 100%;
  }

  .gap img {
    width: 100%;
  }

  .mid {
    text-align: left;
    line-height: 20px;
    width: 50%;
  }

  .mid h2 {
    font-weight: 900 !important;
  }

  .mid h2 span {
    display: block;
    font-weight: 900 !important;
    font-size: 20px;
  }

  .mid p {
    font-size: 15px;
  }

  .gap {
    margin-bottom: 100px;
  }

  .tt img {
    width: 100%;
  }

  .first {
    text-align: left;
    line-height: 20px;
    width: 50%;
  }

  .first h2 {
    font-weight: 900 !important;
  }

  .first h2 span {
    display: block;
    font-size: 20px;
    line-height: 30px;
  }

  .first p {
    font-size: 15px;
  }

  .second {
    font-size: 60px;
  }

  .second h2 span {
    display: block;
    font-weight: 900 !important;
    font-size: 60px;
  }

  .second h2 {
    font-weight: 900 !important;
    font-size: 60px;
  }

  .second p {
    font-size: 15px;
  }

  .ring img {
    float: right;
    width: 100%;
  }

  .limit img {
    width: 100%;
  }

  .gird {
    margin: 50px auto 75px -50px;
    width: 100%;
  }

  .bottom {
    margin-bottom: 125px;
  }

  .gird .col-sm-3 {
    padding: 2px;
  }

  .wizard-details-wrap::after {
    content: "";
    width: 355px;
  }

  .enter-details-wrap::before {
    content: "";
    width: 249px;
    height: 58px;
  }

  .wizard-services-wrap {
    position: relative;
  }

  .basic-details-wrap ::before {
    display: none;
  }

  .wizard-location-wrap:after {
    content: "";
    height: 89px;
    width: 44px;
    top: 0;
  }

  .wizard-services-wrap:before {
    content: "";
    top: 150;
    height: 89px;
    width: 44px;
  }

  .wizard-services-wrap:after {
    content: "";
    height: 75px;
    width: 110px;
    top: calc(50% - 106px);
  }

  .wizard-upload-img-wrap:before {
    content: "";
    height: 75px;
    width: 110px;
    left: -12;
    top: 0;
  }

  .rd-month {
    margin: 0 0 0 -75px;
  }

  .rd-month-label {
    width: 200px;
  }

  .required-documents-wrapper table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .required-documents-wrapper table tbody {
    display: table;
    width: 100%;
  }

  .kaizen-content-wrapper {
    padding: 0 20px 20px;
  }
  .kaizen-content-wrapper p {
    font-size: 16px;
  }
  .resp-100 {
    flex: 0 0 auto;
    width: 100%;
  }

  .fw-1024 {
    flex: 0 0 auto;
    width: 100% !important;
  }

  .rd-month {
    padding: 0;
    margin: 0;
  }

  .rd-month-label {
    width: 100% !important;
  }

  .row.check {
    width: 100% !important;
  }

  .nav-link.location-menu-item {
    padding-left: 40px;
  }

  .navbar .dropdown-area .dropdown-toggle,
  .navbar .nav-link {
    font-size: 16px;
    padding: 0 15px;
  }

  .track-form-box form {
    width: 75%;
  }
}

@media (max-width: 991px) {
  #header {
    zoom: 1;
    top: 40px;
  }
  .bottom-bar {
    height: 80px;
  }
  .bottom-bar-right button {
    padding: 8px 15px !important;
  }
  .mobile-nav-toggle {
    display: block;
  }
  .mb-logo {
    display: block;
  }
  .navbar .nav-link {
    padding: 10px 20px;
    height: fit-content;
    justify-content: flex-start;
    border-bottom: 1px solid #ddd;
  }
  .navbar li.dropdown-area a {
    padding: 0;
  }
  .navbar .nav-link svg {
    right: 0;
    opacity: 1;
    color: #fff;
    padding: 10px;
    transform: none;
    background-color: #05c3de;
  }
  .rtl .navbar .nav-link svg {
    left: 0;
    right: auto;
  }
  .bottom-bar .bottom-bar-left #navbar {
    width: auto;
  }
  .navbar .dropdown-area .dropdown-toggle,
  .navbar .nav-link {
    width: 100%;
    font-size: 16px;
    padding: 6px 20px;
  }
  body.rtl .navbar .dropdown-area i {
    left: 0;
    right: auto;
  }
  .navbar .dropdown-area .dropdown-menu {
    margin: 0;
    display: block;
    min-width: 100%;
    position: unset;
    box-shadow: none;
    border-radius: 0;
  }
  .navbar .dropdown-area .dropdown-item {
    font-size: 13px;
    padding: 8px 0 8px 30px;
    border-radius: 0 !important;
    border-bottom: 1px solid #ddd;
  }
  body.rtl .navbar .dropdown-area .dropdown-item {
    padding: 8px 30px 8px 0;
  }

  .bottom-bar-right {
    display: none;
  }

  .bottom-bar-left {
    justify-content: space-between !important;
    width: 100%;
  }

  .alj-logo-mobile-view {
    padding-top: 25px;
    max-width: 100%;
    margin: auto;
  }

  /* #hero .carousel-container {
    left: 70px;
  }

  body.rtl #hero .carousel-container {
    right: 70px;
  } */

  #hero .carousel-indicators,
  body.rtl #hero .carousel-indicators {
    margin: 0;
    bottom: 35px;
    justify-content: center;
  }

  #hero a.carousel-control-prev,
  #hero a.carousel-control-next {
    display: none;
  }

  .service-image img {
    width: 50%;
    margin: 60px 0 0;
  }

  .resp-bottom-bar-right {
    display: block;
    margin-top: 40px;
  }

  .resp-bottom-bar-right .bottom-bar-right {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0 25px;
    transform: translateX(0);
    justify-content: space-between;
  }

  .resp-bottom-bar-right .bottom-bar-right button,
  .services-wrapper .book-apnt-btn {
    min-width: 100%;
    border-radius: 8px !important;
    padding: 10px 0 !important;
    margin: 0 0 10px 0 !important;
    text-align: center;
  }

  .appointments_content {
    margin: 0;
    width: 100%;
  }

  .footer-para-wrapper .footer-privacy-policy {
    display: none;
  }

  .footer-para-wrapper .footer-para-2 {
    width: 100%;
  }

  .logo-box,
  .links-box,
  .services-box,
  .company-box,
  .social-icons-box {
    margin-bottom: 40px;
  }

  .requiredDocumentsWrapper .form-control,
  .requiredDocumentsWrapper.select__control {
    min-width: 220px !important;
    max-width: 220px !important;
  }

  .enter-details-wrap::before,
  .wizard-details-wrap::after,
  .wizard-location-wrap:after,
  .wizard-services-wrap:before,
  .wizard-services-wrap:after,
  .wizard-upload-img-wrap:before {
    display: none;
  }
}

@media (max-width: 860px) {
  #topbar,
  .bottom-bar {
    padding: 0 30px;
  }

  .headlines {
    padding: 10px 30px;
  }

  .web-footer {
    padding: 50px 30px;
  }

  /* .wizard.sc-app .content .form-control {
    min-width: 100%;
  } */

  .tab-w-100 {
    flex: 0 0 auto;
    width: 100%;
  }

  section#message .ah-section-title {
    width: 100%;
    font-size: 26px;
  }

  section#message .img-desription {
    margin-top: 20px;
  }

  section#message .img-desription .msg-description {
    padding-left: 20px;
  }

  .tab-sm-100 {
    flex: 0 0 auto;
    width: 100%;
  }

  section {
    padding: 60px 30px;
  }

  .sec-other-partners {
    margin-top: 30px;
  }

  .book-btn-wrapper button {
    font-size: 14px !important;
    padding: 10px 20px !important;
  }

  .cookieContainer {
    padding: 0 30px;
  }
}

@media (max-width: 768px) {
  #hero .slider-description {
    font-size: 16px;
    line-height: 20px;
  }

  /* #hero .carousel-container {
    left: 45px;
  }

  body.rtl #hero .carousel-container {
    left: 0;
    right: 45px;
  } */

  .location_heading h2,
  .bring-it-appointments .titles h2 {
    font-size: 36px;
  }

  .carPlateNumber > .form-group {
    width: 100%;
  }
  .car-meta > div {
    margin-right: 0;
  }
  .date-time-select {
    display: block;
  }
  .car-meta,
  .service-select:not(.AddressWrapper),
  .locate-branch {
    display: block;
  }
  .schedule-appointment-main .wizard .content .expand-inp-width,
  #inline_cal,
  #schedule_car_inline_cal,
  .new_upload,
  .car-model .select__control,
  /* .sc-app .wizard .content .form-control, */
  .requiredDropdown > div,
  .collection-delivery-wrapper,
  .AddressWrapper,
  .checkBoxWrapper,
  .requiredDocumentsWrapper,
  #ErrorCollectionAddress,
  #ErrorDeliveryAddress {
    min-width: 100%;
  }
  .locate-branch > div {
    margin: 0;
  }
  .address-details {
    padding: 0;
    max-width: 100%;
  }
  .time-slots-list {
    min-width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  .time-slots-list .slots {
    width: auto;
  }
  .time-slots-list .slots label {
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  .bring-it-appointments .titles a {
    margin: 30px 0 0 !important;
  }

  .appointments_head {
    margin: 30px 0 0;
  }

  .appointments_head h2 {
    font-size: 32px;
  }

  .appointments_content h2 {
    font-size: 24px;
  }

  .newsletter-social {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .newsletter-social .footer-social {
    margin-left: 0;
    margin-top: 30px;
  }

  .newsletter-social .footer-social::before {
    display: none;
  }

  /* REHMATULLAH*/
  .second h2 {
    font-size: 40px;
  }

  .second h2 span {
    display: block;
    font-weight: 900 !important;
    font-size: 40px;
  }

  .partner-img-col img {
    width: 80%;
  }

  .col-sm-8 {
    width: 100% !important;
  }

  .appointments_content p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .about-us-content-wrapper {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .kaizen-image-wrapper img {
    max-width: 100%;
  }

  .step3__control,
  .schedule-appointment-main .select__control {
    min-width: 100% !important;
  }
}

@media (max-width: 767px) {
  #topbar,
  .bottom-bar {
    padding: 0 25px;
  }

  .cookieContainer {
    padding: 5px 25px 25px;
  }

  .headlines {
    padding: 14px 25px;
  }

  section {
    padding: 15px;
  }

  #hero .slider-title,
  #hero .slider-description {
    max-width: 85%;
  }

  .location_heading h2 {
    font-size: 28px;
  }

  .OTP {
    justify-content: flex-start;
  }

  .rtl .OTP {
    justify-content: flex-end;
  }

  .wizard .content p:not(.red-color):not(.no-general),
  .title_form,
  .my-account-link {
    text-align: start;
  }

  .about-us-heading {
    font-size: 28px;
    margin: 30px 0 25px !important;
  }

  .contact-clean {
    margin: -100px 0 25px;
  }
  .contact-clean form::after,
  .contact-clean form::before {
    display: none;
  }
  /* .contact-page-detail {
    height: 280px;
  } */
  .contact-page-detail > div {
    padding: 60px 0 0;
  }
  .contact-page-detail h2 {
    font-size: 30px;
  }
  .contact-clean h2 {
    font-size: 20px;
  }
  .contact-clean form .form-control {
    font-size: 14px;
  }
  .contact-page-detail p {
    font-size: 14px;
    max-width: 85%;
    line-height: 16px;
  }
  .contactInfo {
    display: block;
  }
  .contactInfo p {
    font-size: 18px;
    margin: 15px 0;
  }

  section.margin {
    margin: 25px 0;
  }

  .bring-it-appointments .titles h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .bring-it-appointments .titles a {
    font-size: 14px;
    padding: 15px !important;
  }

  #hero .ah-btn.primary-btn.primary-btn-big {
    font-weight: 600;
    font-size: 12px;
    padding: 6px 15px;
    border-radius: 0 0 15px 0;
    text-transform: capitalize;
  }

  .rtl #hero .ah-btn.primary-btn.primary-btn-big {
    border-radius: 0 0 0 15px;
  }

  #hero .carousel-control-next,
  #hero .carousel-control-prev {
    width: 10%;
  }

  #hero .carousel-control-next i,
  #hero .carousel-control-prev i {
    width: 45px;
    height: 45px;
    font-size: 24px;
    margin-top: 85px;
  }

  .recent_maincontent h2 {
    font-size: 32px;
  }

  .appointments_content h2 {
    margin-top: 0;
    font-size: 22px;
  }

  .service-image img {
    width: auto;
  }

  .service-detail-content h2 {
    font-size: 32px;
  }

  #stepOneForm .username > div:first-child {
    max-width: 100%;
  }

  .wizard-services-wrap {
    position: relative;
    width: 100%;
  }

  .wizard-upload-img-wrap:before {
    content: "";
    height: 61px;
    width: 91px;
    left: -12;
    top: 0;
    display: none;
  }

  .basic-details-wrap ::before {
    content: "";
    height: 64px;
    width: 32px;
    top: 0;
    display: none;
  }

  .wizard-location-wrap:after {
    content: "";
    height: 64px;
    width: 32px;
    right: -7px;
    top: 0;
    display: none;
  }

  .wizard-services-wrap:before {
    content: "";
    top: 150;
    height: 64px;
    width: 32px;
    display: none;
  }

  .wizard-services-wrap:after {
    content: "";
    height: 61px;
    width: 91px;
    right: -7px;
    top: calc(50% - 54);
    display: none;
  }

  .wizard .content p:not(.red-color):not(.no-general) {
    width: 100%;
  }

  .wizard .actions ul {
    width: 100%;
  }

  .wizard .steps > ul {
    width: 100%;
    justify-content: flex-start;
  }

  .wizard-main-wrapper .steps ul li {
    max-width: fit-content;
  }

  .col-sm-4 {
    width: 100%;
    margin-top: 10px;
  }

  .estimation .wizard .actions li a {
    padding: 15px 30px !important;
    min-width: 130px;
  }

  .wizard .actions li a,
  .wizard .actions li input[type="submit"],
  .wizard .actions li button[type="submit"] {
    font-size: 14px;
    padding: 8px 20px !important;
  }

  .signin-login .login-btn {
    font-size: 14px;
  }

  .rtl .signin-login .login-btn {
    font-size: 12px;
  }

  .appointments_head h2 {
    font-size: 28px;
  }

  #stepOneForm .username,
  #stepOneForm .username > div {
    padding-right: 0;
  }

  .at-sc-username > div {
    width: 100%;
    margin-right: 0;
  }

  body.rtl #stepOneForm .username,
  body.rtl #stepOneForm .username > div {
    padding: 0 13px 0 0 !important;
  }

  .estimation-request > div {
    min-width: 100%;
    max-width: 100%;
  }

  .wizard-sec-width {
    width: 100%;
  }
  /* #mrMs,
  .sc-app .wizard .content .form-control {
    min-width: 100%;
  } */
  .phone-no-wrap,
  .input_two > div {
    width: 100%;
    margin-right: 0;
  }
  .phone-no-wrap > div:last-child {
    width: 100%;
  }
  .rtl .phone-no-wrap {
    margin-left: 0;
  }
  .at-sc-username,
  .input_two {
    flex-wrap: wrap;
  }

  .kaizen-top {
    padding: 0 0 40px;
    text-align: center;
  }

  .bring-it-appointments .titles {
    margin: 0 0 20px;
  }

  .fof h1 {
    font-size: 36px;
  }
  .fof h2 {
    font-size: 28px;
  }

  .findus .address-cards svg,
  .find-card a h3 {
    font-size: 18px;
  }

  .login-form-main input[type="submit"],
  .login-form-main button[type="submit"],
  .login-form-main button[type="button"] {
    padding: 8px 22px;
  }

  .car-progress .status-for .fa {
    font-size: 14px;
    margin: 5px 0 30px 0;
  }

  .request-estimate .status-for .fa {
    font-size: 14px;
    margin: 5px 0 30px 0;
  }

  .rtl .re-appointment-btn button {
    border-radius: 0 0 0 15px !important;
  }

  .received-media {
    flex-wrap: wrap;
  }

  .recent-offer-main .row > div:last-child {
    margin-bottom: 0 !important;
  }

  .recent-offer-main {
    padding: 25px 25px 35px;
  }

  .recent-offer-main h4 {
    font-size: 24px;
  }

  .recent-offer-main .service-category-name {
    font-size: 18px;
    margin: 10px 0 20px;
  }

  .recent-offer-main .service-category-name:first-child {
    margin: 0 0 20px;
  }

  .web-footer .services-wrapper {
    flex-direction: column;
  }

  .button-and-expiry-wrap {
    display: block;
  }

  .schedule-appointment-main .form-control:not(.no-width) {
    min-width: 100%;
  }

  .wizard .steps ul {
    flex-direction: column;
  }

  .wizard .steps ul li {
    min-width: 100%;
    margin-bottom: 10px;
    text-align: center;
    border: 1px solid #eee;
    padding: 10px !important;
    border-radius: 6px !important;
    background-image: none !important;
  }

  .wizard .steps ul li span {
    font-size: 14px;
  }

  .wizard .steps ul li.current,
  .wizard .steps ul li.done {
    border-color: transparent;
    background-color: #05c3de;
  }
}
/* 767 end */

@media (max-width: 824px) {
  .recent_maincontenttwo.search_icon img {
    display: none;
  }

  .recent_para {
    font-size: 13px !important;
  }

  .accessibility-wrapper {
    width: 95%;
  }
}

@media (max-width: 575px) {
  #topbar > a,
  #topbar > div {
    margin: 0 6px;
  }

  #topbar .lang-box {
    margin: 0 0 0 8px;
  }

  body.rtl #topbar .lang-box {
    margin: 0 8px 0 0;
  }

  #header .logo img {
    width: 150px;
    transform: none;
  }

  #hero .carousel-indicators,
  body.rtl #hero .carousel-indicators {
    bottom: 20px;
  }

  #hero,
  #hero .carousel-item {
    height: 400px;
  }

  #hero .carousel-item {
    background-size: 100% 100%;
    /* background-position: right; */
  }

  .about-us-image-wrapper img {
    height: 160px;
  }

  .login-form-main {
    padding: 10px;
  }

  .title_form {
    font-size: 20px;
  }

  .login-form-main #phoneCode {
    min-width: 70px;
  }

  .offers-pagination {
    margin: 50px 0;
  }

  .login-modal-close-btn {
    top: 4px;
    right: 4px;
    padding: 6px 8px;
  }

  .lang-box button {
    font-size: 12px;
  }

  .row.no-wrap {
    flex-wrap: wrap;
  }

  .row-divider {
    margin: 0 !important;
  }

  .loader img {
    max-width: 230px;
  }

  section#deals {
    margin: 30px 0;
    padding: 50px 0 !important;
  }

  section#deals .deals-main-wrapper {
    padding: 15px;
  }

  section#message {
    padding: 40px 15px;
  }

  section#message .img-desription img.msg-img {
    display: none;
  }

  section#message .img-desription .msg-description {
    padding-left: 0;
    margin-bottom: 20px;
  }

  section#services:before {
    width: 100%;
    left: 0;
  }

  section#services {
    position: relative;
    padding: 60px 15px;
  }

  #hero .slider-title {
    font-size: 22px;
    line-height: 26px;
  }

  #hero .slider-description {
    font-size: 14px;
    line-height: 16px;
    margin: 5px auto 15px;
  }

  #hero .ah-btn {
    margin-bottom: 70px;
  }

  div#heroCarousel .carousel-indicators button.active:after {
    width: 25px;
    height: 25px;
    left: -11px;
    top: -11px;
  }

  div#heroCarousel .carousel-indicators button {
    width: 3px;
    height: 3px;
    margin-right: 18px;
  }

  body.rtl div#heroCarousel .carousel-indicators button {
    margin-right: 0;
    margin-left: 18px;
  }

  .top-bar .tb-right > div:first-child {
    margin: 0 5px;
    line-height: 0;
  }

  .wizard-main-wrapper .steps ul li:nth-child(1) {
    width: 100%;
    margin-bottom: 10px;
  }

  .wizard-main-wrapper .steps ul li:nth-child(3) {
    width: 100%;
    margin-bottom: 10px;
  }

  select#cars {
    margin: 18px 0 0 0;
  }

  .wizard-sec-width {
    width: 100%;
    margin: 25px auto;
  }

  .acknowledgedWrapper {
    margin: 20px 0 30px;
  }

  .basic-details-wrap ::before {
    content: "";
    height: 43px;
    width: 22px;
    top: 32px;
    display: none;
  }

  .wizard-location-wrap:after {
    content: "";
    height: 43px;
    width: 22px;
    top: 0;
    right: -3px;
    display: none;
  }

  .wizard-services-wrap:before {
    content: "";
    top: 150;
    height: 43px;
    width: 22px;
    display: none;
  }

  .wizard-services-wrap {
    position: relative;
    width: 100%;
  }

  .wizard .content p {
    width: 100%;
  }

  .wizard-upload-img-wrap:before {
    content: "";
    height: 41px;
    width: 62px;
    left: -10px;
    top: 35px;
  }

  .rd-month {
    margin: 0 0 0 0px;
  }

  .wizard-services-wrap:after {
    content: "";
    height: 35px;
    width: 52px;
    right: 27px;
    top: calc(50%);
  }

  .center {
    text-align: center;
  }

  .col-md-10 {
    padding: 0;
  }

  .col-md-12 {
    margin: 0;
  }

  form#verifyUser .col-sm-3 {
    width: 25%;
    flex: 0 0 auto;
  }

  form#verifyUser .col-sm-3 input {
    text-align: center;
  }

  form#verifyUser .box input {
    padding: 10px 10px;
  }

  .accessibility-wrapper,
  .privacy-policy-wrapper,
  .sitemap-wrapper {
    width: 100%;
    padding: 25px 20px;
  }

  .accessibility-wrapper h5,
  .privacy-policy-wrapper h5,
  .required-documents-wrapper h5 {
    font-size: 16px;
  }

  .accessibility-wrapper p,
  .privacy-policy-wrapper p,
  .required-documents-wrapper p,
  .accessibility-wrapper li,
  .privacy-policy-wrapper li,
  .required-documents-wrapper li {
    font-size: 14px;
  }

  .requiredDocumentsWrapper .form-control,
  .requiredDocumentsWrapper.select__control {
    min-width: 270px !important;
    max-width: 270px !important;
  }

  .track-form-box form {
    width: 100%;
    padding: 0 25px;
  }

  .headlines .carousel-item > div {
    display: block;
  }

  .headlines .story {
    margin-top: 8px;
    padding: 0 !important;
  }

  .headlines .brand-logo .img {
    min-height: 38px;
  }

  .headlines .story span,
  .headlines .story span a {
    font-size: 13px;
  }

  .campaign-metadata {
    display: block;

    > div {
      border: 0;
      margin-top: 4px;
      padding: 0 !important;
      font-size: 14px;
    }
  }

  .vehicle-details-wrap > div {
    width: 100%;
  }

  .cookieContainer > div:first-child {
    font-size: 18px;
    line-height: 22px;
  }

  .cookieAcceptBtn {
    font-size: 16px;
  }

  .schedule-appointment-main {
    overflow-x: hidden;
  }
  .careem-code-wrapper form::after {
    width: 100px;
    right: 0 !important;
    padding: 0 !important;
  }
  .careem-code-logo-wrapper img {
    width: 100px;
  }
  .careem-code-logo-wrapper.appointment img {
    width: 130px;
  }
  .careem-code-logo-wrapper.appointment {
    gap: 10px;
  }
  .careem-code-logo-wrapper .collab-icon {
    font-size: 40px;
  }
  .careem-code-wrapper h4 {
    font-size: 18px;
  }
  .careem-code-wrapper p {
    font-size: 14px;
  }
}
/* 575 end */

@media (max-width: 414px) {
  .recent_maincontenttwo {
    line-height: 26px;
    font-size: 20px;
  }

  .second p {
    margin-right: 0px;
  }

  .nav {
    padding-left: none;
  }

  .nav-link.location-menu-item {
    padding-left: 40px;
  }

  body.rtl .bottom-bar-left nav#navbar i.fa-times {
    left: 5px;
    right: auto;
  }

  .recent_maincontenttwo.search_icon img {
    margin: 0 0 0 -60px;
  }

  section#deals .deals-main-wrapper {
    padding: 0px;
  }

  .wizard-main-wrapper .steps ul li.current:nth-child(3) {
    background-image: none !important;
    background-color: #1fc0da !important;
  }

  .wizard-main-wrapper .steps ul li.done:nth-child(3) {
    opacity: 0.5;
    background-color: #1fc0da;
  }
  .sitemap-wrapper table tr td > a,
  .sitemap-wrapper table thead th {
    font-size: 14px !important;
  }
}

@media (max-width: 375px) {
  .location_heading .chartOfProgress span {
    margin: 8px;
  }

  .contact-clean .form-control#phoneNumber {
    padding-left: 5px;
  }

  .phone-number-codes ul {
    width: 250px !important;
  }
}

/* mobile land */
@media (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  .time-slots-list .select-size span label {
    box-sizing: inherit;
    padding: 0;
    width: 90px;
    height: auto;
  }

  .service-image img {
    width: 100%;
  }

  .alj-logo {
    display: none;
  }
}

/* Mobile Responsive */
@media (min-width: 320px) and (max-width: 767px) {
  .car-progress .status-for h5 {
    margin-bottom: 0px;
  }

  body.rtl .logo.me-auto {
    margin-left: auto !important;
    margin-right: 0px !important;
  }

  body.rtl section#message .img-desription .msg-description {
    padding-right: 0px;
    padding-left: 0px;
  }

  body.rtl .footer-social {
    margin-right: 0px;
    margin-left: auto;
    display: flex;
  }

  body.rtl .tb-social {
    display: flex;
  }

  body.rtl .upper {
    margin-top: 0 !important;
    margin-left: unset;
    margin: 0px auto;
  }

  body.rtl .row.upper img {
    margin-bottom: 0px;
  }

  body.rtl .upper .col-sm-6 {
    margin-top: 5% !important;
    padding: 0;
  }

  body.rtl #topbar i {
    margin: 0 5px;
  }

  .login-form-main .phone-no-wrap input.phone-code {
    max-width: 60px !important;
    text-align: center;
    padding: 10px;
  }

  .estimation .wizard .phone-no-wrap input.phone-code {
    max-width: 60px !important;
    text-align: center;
    padding: 10px;
  }

  .contact-clean form {
    padding: 20px;
  }

  .modal-body .close.modal-close-btn {
    margin-left: auto !important;
    display: block;
  }

  .cancel-appointment-modal .controls {
    display: flex;
    margin-top: 10px;
    justify-content: flex-start;
    flex-direction: column-reverse;
  }

  .cancel-appointment-modal .controls .closeBtn,
  .cancel-appointment-modal .controls .cancelBtn {
    margin: 5px 0 !important;
  }

  .pagination {
    flex-wrap: wrap;
    justify-content: center;
  }

  .service-image img {
    width: 50%;
  }
}

@media (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  .time-slots-list .select-size span label {
    box-sizing: inherit;
    padding: 0;
    width: 90px;
    height: auto;
  }

  .service-image img {
    width: 100%;
  }
}
