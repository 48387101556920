.shop-banner {
  height: 80vh;
  background-size: cover;
  background-position: center;
  background-image: url(https://img.freepik.com/free-vector/car-parts-background-with-realistic-images-alloy-disks-steering-wheel-shock-absorbers-with-empty-space_1284-53973.jpg?w=900&t=st=1686408135~exp=1686408735~hmac=3724086e4c4f0d7291899d3d6f50ec09c89bbbf320ebb946637f5935bd7aea09);

  .overlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);

    .content {
      width: 500px;
      padding: 25px;
      position: relative;
      border-radius: 12px;
      border: 1px solid #ddd;
      background-color: #fff;

      h4 {
        left: 0;
        right: 0;
        top: -22px;
        color: #fff;
        margin: 0 auto;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        position: absolute;
        border-radius: 8px;
        padding: 10px 20px 8px;
        max-width: fit-content;
        text-transform: uppercase;
        background-color: #05c3de;
      }

      .car-select {
        margin: 20px 0;

        label {
          font-weight: 600;
          margin-bottom: 5px;
        }

        span {
          color: #444;
          font-size: 14px;
          margin-top: 8px;
          line-height: 18px;
          display: inline-block;
        }
      }
    }
  }
}

.shop-nav {
  user-select: none;
  padding: 55px 0 10px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      display: flex;
      cursor: pointer;
      align-items: center;

      &.indicator {
        margin: 0 20px;
        cursor: auto;

        svg {
          margin: 0;
          font-size: 18px;
        }
      }

      h6 {
        margin: 0;
        color: #ccc;
        font-size: 24px;
        cursor: pointer;
        font-weight: 600;
      }

      svg {
        color: #ccc;
        font-size: 22px;
        margin: -2px 10px 0 0;
      }

      &.active {
        h6,
        svg {
          color: #05c3de;
        }
      }
    }
  }
}

.products-filters {
  padding: 0 20px;
  border: 1px solid #eee;

  > div {
    padding: 22px 0 18px;

    &:not(.no-border) {
      border-bottom: 1px solid #eee;
    }

    &:not(.categories) {
      label {
        display: block;
        font-weight: 500;
        margin-bottom: 5px;
      }
    }

    h6 {
      font-weight: 600;
      margin-bottom: 10px;
    }

    span {
      color: #444;
      font-size: 14px;
      margin-top: 8px;
      line-height: 18px;
      display: inline-block;
    }

    p {
      cursor: pointer;
      font-weight: 500;
      margin: -4px 0 0;
      color: #05c3de;
    }

    &.city {
      svg {
        color: #ffb71b;
      }

      span {
        margin: 8px 10px 0 6px;
        display: inline-block;
      }

      a {
        font-size: 14px;
        color: #05c3de;
        text-decoration: underline !important;
      }
    }
  }

  .categories {
    h6 {
      width: 100%;
      display: flex;
      justify-content: space-between;

      cursor: pointer;
      user-select: none;
      margin-bottom: 15px;
    }

    .single:not(:last-child) {
      margin-bottom: 8px;
    }

    label {
      color: #444;
      font-size: 15px;
    }
  }
}

.e-shop {
  padding: 45px 60px;

  .card-listing {
    margin: 0;
    padding: 0;
    background-color: #fff;

    .single-product {
      margin: 0 0 30px 0;

      .meta-data {
        h5 {
          font-size: 18px;
        }

        span {
          color: #666;
        }

        h6 {
          font-size: 18px;
          color: #05c3de;
        }
      }
    }
  }

  .product-side-img {
    height: 100%;
    overflow: hidden;
    border: 1px solid #eee;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .product-meta {
    padding: 0 0 0 20px;

    h2 {
      font-weight: 800;
      text-transform: uppercase;
    }

    .bullets {
      display: flex;
      align-items: center;
      margin: 10px 0;

      svg {
        margin-left: 10px;
        font-size: 20px;
        color: #757575;
      }

      p {
        margin: 0;
        color: #666;
        padding: 0 12px;
        line-height: 22px;
        border-right: 1px solid #ddd;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: 0;
        }

        span {
          color: #000;
          font-weight: 500;
        }
      }
    }

    .content {
      p {
        color: #666;
        display: inline;
      }

      span {
        cursor: pointer;
        color: #05c3de;
        text-decoration: underline;
      }
    }

    .product-price-container {
      -webkit-box-flex: 1;
      flex-grow: 1;
      display: grid;
      -webkit-box-align: center;
      place-items: center start;
      grid-template-columns: auto 1fr;
      gap: 12px 8px;
      margin: 10px 0 30px;

      .product-price-was {
        color: #05c3de;
        // color: rgb(126, 133, 155);
        text-decoration: line-through;
        font-size: 14px;
        font-weight: 500;
      }

      .product-price-now {
        color: #05c3de;
        font-size: 20px;
        font-weight: bold;
      }

      .product-price-discount {
        color: #05c3de;
        font-size: 16px;
        font-weight: bold;
      }

      .product-price-label {
        color: #ffb71b;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .product-share-container {
      gap: 8px;
      display: flex;
      margin-top: 10px;
    }

    .product-delivery-details-container {
      background-color: rgb(245, 247, 254);
      padding: 15px;
      display: flex;
      flex-direction: column;
      color: rgb(64, 69, 83);

      .delivery-icon {
        color: #05c3de;
        font-size: 18px;
        margin: 0 10px 0 0;
      }

      .delivery-title {
        margin: 0;
        color: rgb(126, 133, 155);
      }

      .delivery-description {
        color: rgb(126, 133, 155);
        font-size: 12px;
        font-weight: 500;
      }
    }

    h4 {
      font-size: 26px;
      margin-top: 20px;
      color: #05c3de;
      font-weight: 600;
    }

    .branches {
      margin-top: 20px;
      padding: 25px 0 0;
      border-top: 1px solid #eee;

      h6 {
        font-size: 18px;
      }

      span {
        color: #666;
        font-size: 14px;
        display: inline-block;
      }

      .car-select {
        max-width: 350px;
        margin: 10px 0 8px;
      }
    }

    .cart-action {
      display: flex;
      margin-top: 30px;
      align-items: center;

      .counter {
        display: flex;
        user-select: none;
        margin: 0 18px 0 0;
        border-radius: 4px;
        align-items: center;
        border: 1px solid #eee;
        padding: 6px 12px 6px 15px;

        input {
          border: 0;
          outline: none;
          max-width: 30px;
          font-size: 20px;
          font-weight: 500;
          margin-left: 10px;
        }

        svg {
          width: 15px;
          height: 15px;
          display: flex;
          cursor: pointer;
          border-radius: 100%;
          align-items: center;
          justify-content: center;
          // background-color: #eee;

          &.false {
            opacity: 0.4;
            pointer-events: none;
          }
        }
      }
    }
  }

  .extra-details {
    color: #666;
    margin-top: 60px;

    h4 {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    ul {
      margin-top: 10px;
      list-style-type: circle;
    }
  }
}

// Arabic layout
.rtl {
  .shop-nav ul li:not(.indicator) svg {
    margin: 0 0 0 10px;
  }

  .e-shop {
    .product-meta {
      .bullets {
        p {
          &:first-child {
            border-right: 0;
            padding-left: 12px;
          }

          &:last-child {
            border-right: 1px solid #ddd;
          }
        }
      }

      .variants-list > div img {
        margin: 0 0 0 15px;
      }

      .cart-action .counter {
        margin: 0 0 0 18px;
      }

      .product-delivery-details-container .delivery-icon {
        margin: 0 0 0 10px;
      }
    }
  }
}

// Media query
@media (max-width: 991px) {
  .shop-nav {
    padding: 50px 0 10px;
  }

  .products-filters {
    margin-bottom: 40px;
  }

  .e-shop {
    padding: 35px 20px;

    .card-listing .row > div:last-child .single-product {
      margin: 0 0 20px 0;
    }

    .product-meta {
      padding: 0;
      margin-top: 35px;
    }
  }
}

@media (max-width: 575px) {
  .shop-banner .overlay .content {
    width: 300px;

    h4 {
      top: -18px;
      font-size: 18px;
    }
  }

  .shop-nav {
    padding: 40px 0 0;

    ul {
      flex-wrap: wrap;

      li {
        margin: 5px 0;

        &.indicator {
          margin: 0 10px;

          svg {
            margin: 0;
            font-size: 14px;
          }
        }

        h6 {
          font-size: 18px;
        }

        svg {
          font-size: 16px;
          margin: -2px 7px 0 0;
        }
      }
    }
  }

  .rtl .shop-nav ul li:not(.indicator) svg {
    margin: 0 0 0 7px;
  }
}

.e-shop .related-products {
  color: rgb(64, 69, 83);
  font-weight: bold;
  font-size: 24px;
  margin-top: 45px;
}

.e-shop .related-products-sub-container {
  background-color: rgb(241, 244, 253);
  padding: 30px;
  border-radius: 4px;

  .card-sub-container {
    display: flex;
    gap: 4px;
    width: 20%;
  }
}

@media (max-width: 991px) {
  .e-shop .related-products-sub-container .card-sub-container {
    width: 30%;

    .custom-product-card
      .custom-sub-container
      .product-image-main-container
      .product-details-image {
      height: 10vh;
    }
  }

  .product-list-image {
    height: 10vh;
  }
}

@media (max-width: 575px) {
  .e-shop .related-products-sub-container .card-sub-container {
    width: 60%;
  }

  .image-gallery {
    padding: 0px !important;
  }
}

.product-details-section {
  margin-bottom: 20px;
}

.product-list-image {
  height: 22vh;
}

.custom-product-card {
  height: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(64, 69, 83);
  padding: 15px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid rgb(229, 229, 229);

  .custom-sub-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 3px;

    .product-image-main-container {
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      .product-image {
        background-color: rgb(126, 133, 155);
        border: 15px solid #fafafa;
        width: 100%;
      }

      .product-details-image {
        height: 22vh;
      }
    }

    .cart-icon {
      width: 38px;
      height: 38px;
      display: flex;
      cursor: pointer;
      align-items: center;
      border-radius: 100%;
      transition: all 0.4s;
      justify-content: center;
      background-color: #05c3de;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      margin-left: auto;
      margin-top: -10px;

      svg {
        color: #fff;
      }
    }
  }

  .product-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.24;
    color: #404553;
    margin-bottom: 0;
    margin-top: 15px;

    + span {
      font-size: 14px;
    }
  }

  .product-price {
    font-weight: 600;
    color: #05c3de;
  }
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 95px !important;
  width: 20px !important;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  width: 0px !important;
  height: 0px !important;
}

.image-gallery {
  padding: 90px;
}

.rtl .custom-product-card .custom-sub-container .cart-icon {
  margin-right: auto;
  margin-left: 0;
}

.rtl .product-detail-tag .detail-tag .img-tag {
  right: 9px;
  left: 0;
}
