.cnd-docs-alert {
  left: 20px;
  bottom: 90px;
  padding: 30px;
  z-index: 996;
  position: fixed;
  max-width: 410px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  h5 {
    font-weight: 700;
  }

  .btn-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: center;
  }

  @media (max-width: 575px) {
    right: 0;
    padding: 25px;
    min-width: 280px;
    max-width: 280px;

    .btn-wrap button {
      width: 100%;
      border-radius: 8px !important;
      margin: 0 0 10px 0 !important;

      &:last-child {
        margin: 0 !important;
      }
    }
  }
}

// Arabic layout

.rtl {
  .cnd-docs-alert {
    left: auto;
    right: 20px;
  }
}
