.car-service-progress {
  top: 60px;
}

.car-service-progress .head {
  margin-bottom: 25px;
}

.car-service-progress > .accordion {
  border-radius: 8px;
  border: 1px solid #eee;
  background-color: #fafafa;
}

.car-service-progress .accordion-item {
  background-color: transparent;
}

.car-service-progress .head h2 {
  margin: 0;
  font-size: 36px;
  font-weight: 800;
}

.car-service-progress .head p {
  color: #777;
  line-height: 20px;
}

.status-for h5,
.status-for h6 {
  margin: 0;
}

.status-for h5 {
  line-height: 28px;
  margin-right: 15px;
}

.car-progress .status-for h4 {
  color: #444;
  cursor: pointer;
  font-weight: 800;
}

.rtl .status-for h5 {
  margin-right: 0;
  margin-left: 15px;
  text-align: right;
}

.rtl .car-progress .status-for {
  text-align: right;
}

.rtl .car-progress .status-for span.fa::after {
  margin-top: 9px;
}

/* .car-progress .col-md-3,
.request-estimate-body .col-md-3 {
  transform: translateY(10px);
} */

.status-for h6 {
  margin: 0;
  font-weight: 600;
  line-height: 24px;
}

.status-for.details a {
  padding: 8px 15px;
  margin: 0 !important;
}

.car-progress .status-for .fa .moreDetails,
.request-estimate-body .status-for .fa .moreDetails {
  color: #05c3de;
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
}

.rtl .car-progress .status-for .fa .moreDetails,
.rtl .request-estimate-body .status-for .fa .moreDetails {
  font-family: "Cairo";
}
.rtl .request-estimate-body .status-for .estimation-data .moreDetails {
  direction: ltr;
  text-align: right;
}
.car-progress .status-for span.fa svg {
  margin: 0 5px;
  cursor: pointer;
  font-size: 14px;
  color: #05c3de;
  transition: 0.3s;
}
.chart-of-progress {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
  font-size: 18px;
  padding: 12px 20px;
  border-radius: 8px;
  align-items: center;
  max-width: fit-content;
  background-color: #f8f8f8;
}
.chart-of-progress p {
  font-size: 14px;
  margin: 0 15px 0 0;
}
.rtl .chart-of-progress p {
  margin: 0 0 0 15px;
}
.chart-of-progress p svg {
  font-size: 13px;
  margin: 0 6px 0 0;
}
.rtl .chart-of-progress p svg {
  margin: 0 0 0 6px;
}
.chart-of-progress .in-progress svg {
  color: #ffb71b;
}
.chart-of-progress .on-pending svg {
  color: lightblue;
  color: lightblue;
}
.chart-of-progress .completed svg {
  color: #23c275;
}
.car-progress .accordion-button:not(.collapsed) .status-for span.fa svg {
  transition: 0.3s;
  transform: rotate(180deg);
}

.step.progress {
  font-size: 1em;
  overflow: visible;
  background-color: #ffb71b;
}

.request-estimate-body .step.progress {
  width: 3em;
  height: 3em;
}

.request-estimate-body .step .content {
  text-align: center;
  min-width: 75px !important;
  transform: translateX(-12px);
}

.request-estimate-body .step .content.last {
  transform: translateX(18px);
}

body.rtl .request-estimate-body .step .content {
  min-width: auto;
}

.progress-tracker .progress-bar,
.progress-tracker .progress-bar::after,
.progress-tracker .pending {
  background-color: lightblue;
}

.progress-tracker .progress-bar.completed,
.progress-tracker .progress-bar.completed::after {
  background-color: #23c275;
}

.progress-tracker .accordion-button:not(.collapsed) {
  color: #000;
  box-shadow: none;
  background-color: transparent;
}

.progress-tracker .detail-body .progress-bar {
  height: 20px;
}

.progress-tracker .detail-body {
  padding: 20px 0;
  max-width: fit-content;
  /* transform: scale(0.9); */
}

/* body.rtl .progress-tracker .detail-body {
  transform: scale(0.9);
} */

.progress-tracker p.title,
.progress-tracker .request-estimate-body p.title {
  margin-top: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}

.per-car-progress .content {
  font-size: 14px;
  margin: 10px 0 0 1em;
}

.rtl .per-car-progress .content {
  right: 100%;
  text-align: right;
  margin: 10px 1em 0 0;
}

.per-car-progress .content.last {
  transform: translateY(-10px);
}

.accordion-button:hover {
  z-index: 0;
}

.car-progress .accordion-button {
  padding: 0;
  max-width: fit-content;
  background-color: transparent;
}

.car-progress.estimate-head .accordion-button {
  padding: 30px 20px 40px;
}

.request-estimate-body.estimate-head {
  position: relative;
  padding: 30px 0 65px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.car-progress .accordion-button::after {
  display: none;
  /* background-image: url(../images/plus.png); */
}

/* .car-progress .accordion-button:not(.collapsed)::after {
  background-image: url(../images/minus.png);
} */

.progress-tracker .stepper > div {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-tracker .stepper > div:last-child {
  border: 1px dashed #fff;
}

.per-car-progress .accordion-button {
  box-shadow: none !important;
}

.per-car-progress > div:not(:last-child) {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.per-car-progress .status-for {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.progress-tracker .current-step {
  width: 76%;
  height: 76%;
  border-radius: 100%;
  border: 3px solid #fff;
}

.progress-tracker .no-data {
  color: #ccc;
  margin-top: 15px;
  text-align: center;
}

.progress-tracker .no-data {
  padding: 18px 0;
}

.progress-tracker .estimated-date {
  color: #303e49;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  margin-bottom: 8px;
  transform: translateX(22px);
}

.rtl .progress-tracker .estimated-date {
  text-align: left;
  transform: translateX(-22px);
}

.accordion-button:not(.collapsed) .estimated-msg {
  visibility: hidden;
}

.progress-tracker .estimated-msg {
  font-size: 14px;
  color: #05c3de;
  font-weight: 600;
}

.progress-tracker .estimated-msg.collapsed {
  margin: 0 -15px 70px 0;
  transform: translateY(90px);
}

.progress-tracker .estimated-msg span {
  color: #303e49;
  font-weight: 500;
}

.progress-tracker .accordion > .accordion-item {
  padding: 25px;
}

.progress-tracker .tab-content {
  padding: 0 15px;
}
.track-estimate .nav-link {
  color: #888;
  font-weight: 500;
  padding: 0 30px 12px;
}
.track-estimate .nav-link {
  border: none;
  border-bottom: 2px solid transparent;
}
.track-estimate .nav-tabs .nav-link.active,
.track-estimate .nav-tabs .nav-link.active:hover {
  color: #05c3de;
  border-bottom-color: #05c3de;
}
.track-estimate .nav-tabs {
  padding: 0;
  border-bottom: none;
  justify-content: center;
}
.track-estimate .nav-tabs .nav-link:hover {
  border-color: white;
}
.track-estimate .notification-icon {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}
.track-estimate .read-comments {
  margin-top: 5px;
}
.fast-collect-notifications {
  padding: 30px 0 0;
}
.fast-collect-notifications .notification-icon {
  margin: 0 0 25px;
  text-transform: uppercase;
  justify-content: flex-start;
}
.fast-collect-notifications .notification-icon h4 {
  color: #444;
  font-weight: 800;
  margin: 0 15px 0 0;
}
.rtl .fast-collect-notifications .notification-icon h4 {
  margin: 0 0 0 15px;
}
.track-estimate .notification-icon svg {
  font-size: 28px;
  color: #05c3de;
  cursor: pointer;
}
.request-estimate-body .estimation-data {
  display: grid;
  margin: 20px 0 0;
}
.request-estimate-body .estimation-data.desktop {
  text-align: right;
}
.rtl .request-estimate-body .estimation-data.desktop {
  text-align: left;
}
.request-estimate-body .estimation-data .estimation-btn {
  width: 100%;
  margin: 15px auto 0;
}
.request-estimate-body .estimation-data .estimation-btn a {
  padding: 10px 15px;
}
.request-estimate-body .estimation-data .pdf-link {
  cursor: auto;
}
.request-estimate-body .estimation-data .pdf-link button {
  padding: 0;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: #ffb71b;
  text-decoration: underline;
  background-color: transparent;
}
.request-estimate-body .stepper {
  height: auto;
}
.request-estimate-body.estimation-approved {
  padding: 30px 0;
}
.quote-body.estimation-approved.estimation-booked {
  padding: 30px 0 50px;
}
.notification-icon .badge-icon {
  color: #000;
  font-size: 12px;
  min-width: 20px;
  height: fit-content;
  padding: 4px 5px 3px 6px;
  background-color: #ffb71b !important;
}
.request-estimate-body .status-for h5 {
  text-align: left;
}
.rtl .request-estimate-body .status-for h5 {
  text-align: right;
}

/* Notification Modal */
.notifications-container .toast-container {
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
  min-width: 100% !important;
  position: relative !important;
}
.notifications-container .toast-container .toast {
  box-shadow: none;
}
.notifications-container .admin-message {
  min-width: 100%;
  margin-bottom: 20px;
}
.fast-collect-notifications .toast-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fast-collect-notifications .admin-message {
  width: 49%;
  margin: 0 0 10px;
  min-width: auto;
}
.fast-collect-notifications .admin-message .toast-header {
  justify-content: space-between;
}
.fast-collect-notifications .admin-message .toast-header strong {
  margin: 0 10px 0 0 !important;
}
.rtl .fast-collect-notifications .admin-message .toast-header strong {
  margin: 0 0 0 10px !important;
}
.notifications-container .admin-message .read-notification {
  flex-wrap: wrap;
  border-bottom: none;
  background-color: #f2f2f2;
}
.notifications-container .admin-message .unread-notification {
  background-color: #05c3de;
  color: white;
  border-bottom: none;
}
.notifications-container .admin-message .toast-header strong,
.notifications-container .admin-message .toast-header small {
  font-weight: 600;
}
.notifications-container .admin-message .toast-body {
  background-color: white;
}
.fast-collect-notifications .admin-message .toast-body p {
  font-weight: normal;
}
.notifications-container .review-notification-btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.rtl .notifications-container .toast-container {
  direction: rtl;
}
.rtl .notifications-container .admin-message .toast-header strong {
  margin-right: 0 !important;
}
.rtl .notifications-container .admin-message .toast-header small {
  margin-right: auto;
}
.rtl .fast-collect-notifications .admin-message .toast-header small {
  margin-right: 0;
}

.estimation-expired {
  position: relative;
}
.estimation-expired::after {
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
}
.estimation-expired .expired-label,
.estimation-approved .closed-label {
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 20px 0 0;
  padding: 5px 10px;
  line-height: 16px;
  border-radius: 4px;
  max-width: fit-content;
  background-color: #dc3545;
}

.estimation-expired .pdf-link {
  pointer-events: none;
}
.estimation-approved .expired-label {
  margin: 20px 0 40px;
}
.comments-modal .image-guide-modal-header {
  margin-bottom: 25px;
}
.comments-modal .image-guide-modal-header p {
  margin-bottom: 30px;
}
.comments-modal .image-guide-modal-header h6 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.comments-modal .image-guide-modal-header h6 span {
  margin: 0 3px;
}
.estimation-booked:not(.estimation-expired) .estimation-data {
  margin: 15px 0 0 !important;
}
.satisfied-not-satisfied-btns {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}
.vehicle-collection-schedule {
  padding: 20px 40px;
}
.vehicle-collection-schedule .title_form + p {
  line-height: 22px;
}

/* Close reason modal */
.view-comments {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 14px;
  color: #05c3de;
  line-height: normal;
  background-color: transparent;
}
.cancel-reason-body {
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
}
.cancel-reason-body h5 {
  font-weight: 700;
  color: #dc3545;
  text-align: start;
}
.estimation-canceled .stepper > div:last-child {
  background-color: #dc3545;
}
span.view-more-btn {
  display: block;
  cursor: pointer;
  color: #ffb71b;
  font-weight: 600;
  margin: 10px auto 0;
  max-width: fit-content;
  border-bottom: 2px solid currentColor;
}

@media screen and (max-width: 1199px) {
  .track-addons {
    padding-top: 35px;
  }
  .reason-modal-body {
    padding: 30px 30px 20px;
  }
  .progress-tracker .detail-body .row > div {
    width: 100%;
  }
  .status-for.details {
    padding-left: 0;
  }
  .status-for h5 {
    font-size: 18px;
    line-height: 26px;
  }
  .status-for h6 {
    font-size: 16px;
  }

  .track-addons .addons-listing .track-addons-panel {
    max-height: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .status-for h6 {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
  }
  .car-progress .col-md-8 {
    transform: scale(0.9);
  }
  .request-estimate-body .col-md-8 {
    transform: scale(0.9);
  }
  .request-estimate-body .estimation-data .pdf-link {
    min-width: max-content;
  }
}

@media screen and (max-width: 767px) {
  .progress-tracker .accordion > .accordion-item {
    padding: 20px;
  }

  .progress-tracker .estimated-date {
    font-size: 12px;
    transform: translateX(14px);
  }

  .rtl .progress-tracker .estimated-date {
    transform: translateX(-14px);
  }

  .css-bbq5bh {
    display: block !important;
  }

  .progress-tracker {
    margin: 0 0 30px;
  }

  .status-for h5 {
    margin-bottom: 30px;
  }

  .request-estimate-body .status-for h5 {
    margin-bottom: 40px;
    padding: 0 18px 0 0;
  }

  .rtl .request-estimate-body .status-for h5 {
    padding: 0 0 0 18px;
  }

  .progress-tracker p.title {
    font-size: 12px;
    line-height: 16px;
  }

  .progress-tracker .accordion-body {
    padding: 0;
  }

  .status-for.details h5 {
    margin: 0;
    font-size: 12px;
    text-align: left;
  }

  .status-for.details > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .status-for.details a {
    font-size: 12px;
    margin: 0 !important;
    padding: 8px 15px 6px;
  }

  .status-for.details {
    padding: 10px 10px 0;
  }

  /* .progress-tracker .accordion-button::after,
  body.rtl .progress-tracker .accordion-button::after {
    display: block;
    margin: 70px auto 0;
    background-size: 15px;
  }

  .progress-tracker .dated .accordion-button::after,
  .rtl .progress-tracker .dated .accordion-button::after {
    margin: 110px auto 0;
  } */

  .chart-of-progress p {
    font-size: 12px;
    margin: 0 10px 0 0;
  }

  .chart-of-progress p svg {
    font-size: 11px;
    margin: 0 6px 0 0;
  }

  .progress-tracker .estimated-msg.collapsed {
    margin: 0 -10px 0 0;
  }

  .rtl .progress-tracker .estimated-msg {
    font-size: 14px;
    margin: 0 0 15px 15px;
  }

  .rtl .progress-tracker .estimated-msg.collapsed {
    margin: 0 0 0 -10px;
  }

  .car-progress .col-md-8,
  .request-estimate-body .col-md-8 {
    width: 100%;
    transform: scale(1);
  }
  /* .car-progress .accordion-button:not(.collapsed)::after {
    margin: 60px auto 0;
  } */
  .track-estimate .nav-tabs {
    margin-bottom: -15px;
  }
  .track-estimate .nav-link {
    font-size: 14px;
    padding: 0 10px 5px;
    margin-bottom: 15px;
  }
  .chart-of-progress {
    margin: 30px auto 10px;
  }
  .track-estimate .notification-icon:not(.fast-collect-bell) {
    right: 0;
    top: 30px;
    margin-top: 0;
    position: absolute;
    justify-content: end !important;
  }
  .rtl .track-estimate .notification-icon:not(.fast-collect-bell) {
    left: 0;
    right: auto;
  }
  .track-estimate .notification-icon.read-comments:not(.fast-collect-bell) {
    top: 60px;
  }
  .request-estimate-body.estimation-approved {
    position: relative;
    padding: 30px 0 75px !important;
  }
  .request-estimate-body .estimation-data .estimation-btn {
    margin: 15px auto 40px;
  }

  .estimation-approved:not(.estimation-canceled) .status-for h5 {
    margin-bottom: 0;
  }
  .track-estimate .notification-icon i {
    font-size: 24px;
    margin: 0 2px 0;
  }
  .progress-tracker .no-data {
    font-size: 18px;
  }
  .estimation-expired:not(.estimation-approved):not(.estimation-canceled) h5 {
    margin-bottom: 0;
  }
  .estimation-expired:not(.estimation-approved) .expired-label {
    margin: 20px 0 35px;
  }
  .track-estimate .notification-icon svg {
    font-size: 24px;
  }
  .fast-collect-notifications .admin-message {
    width: 100%;
  }
  .satisfied-not-satisfied-btns button {
    font-size: 12px !important;
    line-height: 16px;
  }
  .vehicle-collection-schedule {
    padding: 20px 0;
  }
  .vehicle-collection-schedule .title_form {
    text-align: center;
  }
  .estimation-booked:not(.estimation-expired) .estimation-data {
    margin: 15px 0 40px !important;
  }
  .car-service-progress {
    margin-top: 30px;
  }
  .car-service-progress .head h2 {
    font-size: 28px;
    line-height: 32px;
  }
}
